<template>
  <div class="coach">
    <img style="display: block;" src="../../assets/coach.png" alt="" />
    <downBtn
      :topShow="true"
      :isWx="isWx"
      @down="download"
      @wechatShow="wechatShow"
    ></downBtn>
    <!-- <downBtn :bottomShow="true" :topShow="true" @down="download"></downBtn> -->
    <!-- <div class="mask-box pos-fiexd" v-show="maskShow">
      <div class="mask-content p-a-c bg-fff">
        <div class="close text-right">
          <img
            src="@/assets/close.png"
            alt=""
            style="width: 14px; height: 14px"
            @click="changeMask"
          />
        </div>
        <div class="content text-center">
          <div class="text">下载洋葱韩语app</div>
          <div class="btn" @click="download">
            <span>去下载</span>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import downBtn from "@/components/downBtn.vue";
import mixin from "@/utils/mixin";
export default {
  mixins: [mixin],
  name: "coach",
  components: {
    downBtn,
  },
  props: {},
  data() {
    return {
      maskShow: false,
    };
  },
  created() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.coach {
  img {
    width: 100%;
  }
}
</style>
